import { Stack, TextField } from "@mui/material";
import { updateDoc } from "firebase/firestore";
import type { PhoneNumber } from "libphonenumber-js";
import {
  AsYouType,
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Network } from "../../types/network.db";
import type { Station } from "../../types/station.db";
import { updatedAt } from "../../util/firebase";

function EditSupportDialog({
  open,
  handleClose,
  station,
  network,
}: {
  open: boolean;
  handleClose: () => void;
  station: WithDocRef<Station>;
  network: WithDocRef<Network>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      let phoneNumber: PhoneNumber | null = null;
      try {
        if (station.support_phone) {
          phoneNumber = parsePhoneNumber(station.support_phone, "US");
        }
      } catch (e) {
        console.warn(e);
      }
      reset({
        support_phone:
          phoneNumber?.formatNational() ?? station.support_phone ?? "",
        support_email: station.support_email,
        support_website: station.support_website,
      });
    }
  }, [open, reset, station]);

  const onSubmit = async (data: any) => {
    let phoneNumber: PhoneNumber | null = null;

    try {
      if (data.support_phone) {
        phoneNumber = parsePhoneNumber(data.support_phone, "US");
      }
    } catch (e) {
      console.warn(e);
    }

    try {
      await updateDoc(station.docRef, {
        support_phone: phoneNumber?.format("E.164") || null,
        support_email: data.support_email?.trim() || null,
        support_website: data.support_website?.trim() || null,
        ...updatedAt,
      });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update station", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Edit Support"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"support_phone"}
          control={control}
          rules={{
            validate: (v) =>
              v === "" ||
              isPossiblePhoneNumber(v, "US") ||
              "Not a valid Phone Number",
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Support Phone"
              placeholder={
                network.support_phone
                  ? parsePhoneNumber(
                      network.support_phone,
                      "US"
                    ).formatNational()
                  : ""
              }
              helperText={error?.message}
              error={Boolean(error)}
              onChange={(event) => {
                const value = new AsYouType("US").input(event.target.value);
                field.onChange(value);
              }}
              fullWidth
            />
          )}
        />
        <Controller
          name={"support_email"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Support Email"
              placeholder={network.support_email ?? ""}
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
        <Controller
          name={"support_website"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Support Website"
              placeholder={network.support_website ?? ""}
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default EditSupportDialog;
