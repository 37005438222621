import { Button, Link, Typography } from "@mui/material";
import { parsePhoneNumber } from "libphonenumber-js";
import React, { useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Network } from "../../types/network.db";
import type { Station } from "../../types/station.db";

import EditSupportDialog from "./EditSupportDialog";

function SupportCard({
  station,
  network,
}: {
  station: WithDocRef<Station>;
  network: WithDocRef<Network>;
}): JSX.Element {
  const [open, setOpen] = useState(false);

  const supportPhone = station.support_phone ?? network.support_phone;
  const supportEmail = station.support_email ?? network.support_email;
  const supportWebsite = station.support_website ?? network.support_website;

  return (
    <>
      <PartitionedCard
        title={"Support"}
        action={
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            Edit
          </Button>
        }
        spacing={4}
      >
        <PartitionedCardItem
          title={"Phone"}
          caption={
            network.support_phone && !station.support_phone
              ? "From Network"
              : ""
          }
        >
          <Typography>
            {supportPhone
              ? parsePhoneNumber(supportPhone, "US").formatNational()
              : "-"}
          </Typography>
        </PartitionedCardItem>

        <PartitionedCardItem
          title={"Email"}
          caption={
            network.support_email && !station.support_email
              ? "From Network"
              : ""
          }
        >
          {supportEmail ? (
            <Link href={`mailto:${supportEmail}`} target={"_blank"}>
              {supportEmail}
            </Link>
          ) : (
            <Typography>-</Typography>
          )}
        </PartitionedCardItem>

        <PartitionedCardItem
          title={"Website"}
          caption={
            network.support_website && !station.support_website
              ? "From Network"
              : ""
          }
        >
          {supportWebsite ? (
            <Link href={supportWebsite} target={"_blank"}>
              {supportWebsite}
            </Link>
          ) : (
            <Typography>-</Typography>
          )}
        </PartitionedCardItem>
      </PartitionedCard>
      {open && (
        <EditSupportDialog
          open={true}
          handleClose={() => setOpen(false)}
          station={station}
          network={network}
        />
      )}
    </>
  );
}

export default SupportCard;
